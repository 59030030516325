@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #f1f3f6;
    font-family: 'Roboto', sans-serif;
}

/* hover navbar dropdown arrow */
.arrow_down {
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 11px solid #fff;
}
/* hover navbar dropdown arrow */

/* login signup sidebar bg image */
.loginSidebar {
    background-image: url("https://static-assets-web.flixcart.com/www/linchpin/fk-cp-zion/img/login_img_c4a81e.png");
    background-position: center 85%;
    background-repeat: no-repeat;
}
/* login signup sidebar bg image */